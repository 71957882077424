export const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 5,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 2,
	},
};

export const responsive_social = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 5,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

export const YT_LINK = "https://youtube.com/@dosgardeniastours3154?si=JA6f5R3sWQvbNGnk";
export const WS_PHONE = "51917838795";
export const WS_LINK = "https://wa.link/2mpnx4";
export const FACE_LINK = "https://www.facebook.com/2Gardenias/";
export const INST_LINK = "https://www.instagram.com/dos_gardenias_tours";
export const TK_LINK = "https://www.tiktok.com/@dosgardeniastours";
export const TADVISOR_LINK =
	"https://www.tripadvisor.com.mx/Attraction_Review-g294316-d20979000-Reviews-Dos_Gardenias_Tours_Peru-Lima_Lima_Region.html";
export const MAIL_LINK = "ventas@dosgardeniastours.com";

export const BACKEND_URL =
	"https://dosgardenias-backend-263c6e62f62a.herokuapp.com";

export const TODOS = -1;
export const NATIONAL = 1;
export const INTERNATIONAL = 2;
export const VPROMO = 3;

export const ESNNA =
	"https://s3.sa-east-1.amazonaws.com/www.dosgardeniastours.com/Documentos/AFICHE_ESNNA_AGENCIAS.pdf";
export const IATA =
	"https://s3.sa-east-1.amazonaws.com/www.dosgardeniastours.com/Documentos/iata_certificate.pdf";
export const PROTECTION =
	"https://s3.sa-east-1.amazonaws.com/www.dosgardeniastours.com/Documentos/POLITICA_DE_PROTECCION_DE_DATOS.pdf";
export const TERMS =
	"https://s3.sa-east-1.amazonaws.com/www.dosgardeniastours.com/Documentos/TERMINOS_Y_CONDICIONES_DOS_GARDENIAS_TOURS.pdf";
export const QUALITY =
	"https://s3.sa-east-1.amazonaws.com/www.dosgardeniastours.com/Documentos/SGC_PO_01_Politica+de+Calidad_01.pdf";
export const MPERU =
	"https://s3.sa-east-1.amazonaws.com/www.dosgardeniastours.com/Documentos/licencia_marca_peru.pdf";
export const MINCETUR =
	"https://s3.sa-east-1.amazonaws.com/www.dosgardeniastours.com/Documentos/CONSTANCIA_INSCRIP_DIRECTORIO_NACIONAL_DE_PRESTADORES_DE_SERV.TURISTICOS_CALIFICADOS.pdf";

export const INSTAGRAM = 2;
export const TIKTOK = 1;
