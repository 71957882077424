import { BACKEND_URL } from "../components/utils";

export default async function saveSub(body) {
	try {
		let response = await fetch(`${BACKEND_URL}/subscribe`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(body),
		});

		const data = await response.json();

		return data;
	} catch (e) {
		console.error("aqui", e.message);

		throw e;
	}
}
