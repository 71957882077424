export default function WSButton() {
	return (
		<div
			className="fixed right-4 md:right-10 bottom-14 bg-white rounded-full 
             shadow-xl h-20 md:h-24 flex justify-center
                hover:bg-dgwhite
            "
		>
			<a
				href="https://wa.me/51917838795"
				target="_blank"
				rel="noreferrer"
				className="m-auto"
			>
				<img
					src="/images/wsbutton.png"
					className="h-20 md:h-24  p-4 md:p-5"
					alt="whatsapp button"
				/>
			</a>
		</div>
	);
}
