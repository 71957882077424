import Dialog from "./dialog";

export default function SorryDialog({ open, onClose }) {
	return (
		<Dialog onClose={onClose} isOpen={open} title={"Atención"}>
			<p className="text-xl text-center">
				Nos disculpamos, pero la promoción que estabas buscando ha expirado
				<br />
				Te invitamos a ver nuestras promociones actuales en Dos Gardenias
				Tours
				<br />
				Gracias por tu comprensión y por seguir con nosotros
				<br />
				<br />
				<span className="font-bold">Atte. Dos Gardenias Tours</span>
			</p>
		</Dialog>
	);
}
