import { useContext, useRef, useState } from "react";
import Dialog from "./dialog";
import { WS_PHONE } from "./utils";
import { button_text, coti_dialog_text } from "../translation";
import { HeaderContext } from "./header";

export default function CotiDialog({ isOpen, onClose }) {
	const cityRef = useRef();
	const nameRef = useRef();
	const emailRef = useRef();
	const sdateRef = useRef();
	const edateRef = useRef();
	const [kind, setKind] = useState("Viaje Nacional");
	const cantRef = useRef();
	const commentsRef = useRef();
	
	const {lang} = useContext(HeaderContext);

	const title = (
		<div className="w-full">
			<p className="text-3xl text-dgblue font-ivymode">{coti_dialog_text.title[lang]}</p>
			<p className="font-poppins text-lg font-normal  max-w-full">
				{coti_dialog_text.message[lang]}	
			</p>
			
		</div>
	);
	const onSubmit = (e) => {
		e.preventDefault();

		const name = nameRef.current.value.trim();
		const city = cityRef.current.value.trim();
		const sdate = sdateRef.current.value.trim();
		const edate = edateRef.current.value.trim();
		const comments = commentsRef.current.value.trim();
		const email = emailRef.current.value.trim();

		const content = `Hola me llamo ${name}, mi correo electrónico es ${email}.\nDeseo una cotización hacia "${city}" con fecha de salida ${sdate} y retorno el ${edate}. \nTipo de viaje : ${kind} para # ${cantRef.current.value} persona(s). \nInfo adicional: ${comments}`;
		const encondedText = encodeURIComponent(content);
		const link = `https://wa.me/${WS_PHONE}/?text=${encondedText}`;

		if (document) {
			const a = document.createElement("a");
			a.href = link;
			a.target ="_blank"; // faltaba esto
			a.rel = "noopener noreferrer";
			a.style.display = "none";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	};

	const today = new Date().toISOString().split('T')[0];


	return (
		<Dialog title={title} isOpen={isOpen} onClose={onClose}>
			<form onSubmit={onSubmit}>
				<div className="grid grid-cols-2 font-poppins gap-4">
					<div className="col-span-2">
						<p className="text-lg">{coti_dialog_text.city[lang]}</p>
						<input
							className="w-full border-dgtitle border-2 px-4 py-2 rounded"
							ref={cityRef}
							required
						/>
					</div>
					<div className=" col-span-2 md:col-span-1">
						<p className="text-lg">{coti_dialog_text.name[lang]}</p>
						<input
							className="w-full border-dgtitle border-2 px-4 py-2 rounded"
							ref={nameRef}
							required
						/>
					</div>
					<div className="col-span-2 md:col-span-1">
						<p className="text-lg">{coti_dialog_text.email[lang]}</p>
						<input
							className="w-full border-dgtitle border-2 px-4 py-2 rounded"
							ref={emailRef}
							type="email"
							required
							lang="en"
							inputMode="email"
						/>
					</div>
					<div className="col-span-1">
						<p className="text-lg">{coti_dialog_text.from[lang]}</p>
						<input
							className="w-full border-dgtitle border-2 px-4 py-2 rounded"
							ref={sdateRef}
							type="date"
							required
							min={today}
						/>
					</div>
					<div className="col-span-1">
						<p className="text-lg">{coti_dialog_text.to[lang]}</p>
						<input
							className="w-full border-dgtitle border-2 px-4 py-2 rounded"
							ref={edateRef}
							type="date"
							required
							min={today}
						/>
					</div>
					<div>
						<p className="text-lg">{coti_dialog_text.kind[lang]}</p>
						<select
							className="w-full border-dgtitle border-2 px-4 py-2 rounded"
							value={kind}
							onChange={(e) => setKind(e.target.value)}
						>
							<option value="Paquete (Vuelo y Hotel)">
								{coti_dialog_text.items.full[lang]}
							</option>
							<option value="Hospedaje">{coti_dialog_text.items.hotel[lang]}</option>
							<option value="Vuelo">{coti_dialog_text.items.flight[lang]}</option>
						</select>
					</div>
					<div>
						<p className="text-lg">{coti_dialog_text.number[lang]}</p>
						<input
							className="w-full border-dgtitle border-2 px-4 py-2 rounded"
							ref={cantRef}
							type="number"
							step="1"
							min={1}
							max={30}
							required
							inputMode="numeric"
						/>
					</div>
					<div className="col-span-2">
						<p className="text-lg">{coti_dialog_text.comments[lang]}</p>
						<textarea
							className="w-full border-dgtitle border-2 px-4 py-2 rounded"
							ref={commentsRef}
							rows={3}
						></textarea>
					</div>
					<div className="col-span-2 flex flex-row justify-end gap-x-4 ">
						<div>
							<button
								type="button"
								onClick={onClose}
								className="hover:shadow-xl hover:bg-dgblue hover:text-white rounded-full border-2 border-dggreen w-32 py-1 text-dggreen"
							>
								{button_text.cancel[lang]}
							</button>
						</div>
						<div>
							<button
								type="submit"
								className="hover:shadow-xl hover:bg-dgblue  rounded-full border-2 border-dggreen w-32 py-1 text-dgwhite bg-dggreen"
							>
								{button_text.send[lang]}
							</button>
						</div>
					</div>
				</div>
			</form>
		</Dialog>
	);
}
