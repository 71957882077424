import { useEffect } from "react";

const Dialog = ({ isOpen, onClose, title, children,sxh }) => {
	useEffect(() => {
		if (document) {
			if (isOpen) {
				// add no scroll to body

				document.getElementById("home").classList.add("overflow-hidden");
			} else {
				document.getElementById("home").classList.remove("overflow-auto");
			}
		}
	}, [isOpen]);
	return (
		<>
			{isOpen && (
				<div className="fixed inset-0 z-[9999] overflow-auto bg-black bg-opacity-50 flex">
					<div className={`relative py-6 px-4 md:p-8 bg-white w-full max-w-md lg:max-w-lg xl:max-w-2xl max-h-full md:max-h-[85vh]   ${sxh ? sxh :'mx-auto my-0'} md:m-auto  flex-col flex rounded-lg shadow-lg `}>
						<div className="w-[100%] flex justify-between items-center mb-2">
							<div className="text-lg font-semibold font-ivymode w-full">
								{title}
							</div>
							<button
								type="button"
								onClick={onClose}
								className="absolute z-50 top-4 right-4  p-2 hover:shadow-xl rounded-full border-0  text-gray-500 hover:text-gray-700 focus:outline-none "
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-8 w-8 md:h-6 md:w-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
						<div className="flex flex-col space-y-2 h-full">{children}</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Dialog;
