import { useContext } from "react";
import Dialog from "./dialog";
import { HeaderContext } from "./header";
import { confirm_dialog_text } from "../translation";

export default function ConfirmSuscriptionDialog(props){
    // const [open, setOpen] = useState(false);

    const {open, onClose} = props;
    const { lang } = useContext(HeaderContext);

    return(
        <Dialog title="" isOpen={open} onClose={onClose} sxh={"h-1/3 mx-4 my-auto"}>
            <div className="text-center flex flex-col h-full">
                <div className="m-auto h-fit">
                    <p className="font-ivymode text-2xl whitespace-pre text-dggreen font-bold mb-4">{confirm_dialog_text.title[lang]}</p>
                    <p className="font-poppins text-xl">{confirm_dialog_text.subtitle[lang]}</p>
                </div>
            </div>
            
        </Dialog>
    )
}