export const home_text = {
	main: {
		en: "WHERE DO YOU <br/> WANT TO GO?",
		es: "¿A DÓNDE &nbsp;&nbsp;<br/>QUIERES IR?",
	},
	chatgpt: {
		en: "DON'T KNOW WHERE TO TRAVEL?",
		es: "¿NO SABES A DÓNDE VIAJAR?",
	},
	placeholder: {
		en: "Ask our virtual assistant and we'll help you with your trip",
		es: "Pregúntale a nuestro asistente virtual y te ayudaremos con tu viaje",
	},
	promos: {
		en: "LATEST PROMOS",
		es: "ÚLTIMAS PROMOCIONES",
	},
	company: {
		en: "WE ARE YOUR TRAVEL AGENCY SPECIALIZED IN NATIONAL AND CARIBBEAN DESTINATIONS",
		es: "SOMOS TU AGENCIA DE VIAJES ESPECIALIZADA EN DESTINOS NACIONALES Y DEL CARIBE",
	},
	more_title: {
		en: "DO YOU WANT TO SEE MORE?",
		es: "¿QUIERES VER MÁS?",
	},
	more_subtitle: {
		en: "Dream and unmissable packages!",
		es: "¡Paquetes soñados e imperdibles!",
	},
	certi_1: {
		en: "Certified with",
		es: "Certificados con",
	},
	certi_2: {
		en: "Accredited by",
		es: "Acreditados por",
	},
	certi_3: {
		en: "Representative of",
		es: "Representante de",
	},
	school_1: {
		en: "IS YOUR FINAL\n YEAR OF SCHOOL?",
		es: "¿ES TU ÚLTIMO\n AÑO DE ESCUELA?",
	},
	school_2: {
		en: "Take advantage of the promotional\ntravel packages we have for you",
		es: "Aprovecha los paquetes de viajes\nde promoción que tenemos para ti",
	},
	wedding_1: {
		en: "UNIQUE WEDDINGS IN\n EXCLUSIVE DESTINATIONS",
		es: "BODAS ÚNICAS EN\n DESTINOS EXCLUSIVOS"
	},
	wedding_2: {
		en: "Experience an unforgettable journey\nwith our wedding packages",
		es: "Vive una experiencia inolvidable\ncon nuestros paquetes de boda"
	}
};

export const header_text = {
	coti: {
		en: "Get a quote for my trip",
		es: "Cotizar mi viaje",
	},
};

export const footer_text = {
	newsletter: {
		en: "Receive offers, travel guides, and promotions here",
		es: "RECIBE OFERTAS, GUÍAS DE <br /> VIAJE Y PROMOCIONES AQUÍ",
	},
	services: {
		en: "Services",
		es: "Servicios",
		coti: {
			en: "Get quotes",
			es: "Cotizar paquetes de viaje",
		},
		national: {
			en: "Domestic flights",
			es: "Vuelos nacionales",
		},
		international: {
			en: "International flights",
			es: "Vuelos internacionales",
		},
		vpromo: {
			en: "Graduation Trips",
			es: "Viajes de Promoción",
		},
	},
	certis: {
		en: "Certifications",
		es: "Certificaciones",
		esnna: {
			en: "ESNNA",
			es: "ESNNA",
		},
		terms: {
			en: "Terms and Conditions",
			es: "Términos y condiciones",
		},
		mincetur: {
			en: "Certified by MINCETUR",
			es: "Certificación MINCETUR",
		},
	},
	policy: {
		en: "Policies",
		es: "Políticas",
		secure: {
			en: "Secure Purchase",
			es: "Compra Segura",
		},
		privacy: {
			en: "Privacy Policy",
			es: "Política de Privacidad",
		},
		protection: {
			en: "Personal Data Protection",
			es: "Protección de Datos Personales",
		},
		quality: {
			en: "Quality Policy",
			es: "Política de Calidad",
		},
	},
	dev: {
		en: "Powered by Devosinc",
		es: "Desarrollado por Devosinc",
	},
};

export const button_text = {
	coti: {
		en: "Get a quote",
		es: "Cotizar",
	},
	ask: {
		en: "Ask us",
		es: "Preguntar",
	},
	aboutus: {
		en: "About us",
		es: "Conócenos",
	},
	seemore: {
		en: "See more",
		es: "Ver más",
	},
	international: {
		en: "International",
		es: "Internacional",
	},
	national: {
		en: "National",
		es: "Nacional",
	},
	follow_tk: {
		en: "FOLLOW US ON TIKTOK",
		es: "SIGUENOS EN TIKTOK",
	},
	follow_inst: {
		en: "FOLLOW US on INSTAGRAM",
		es: "SIGUENOS EN INSTAGRAM",
	},
	follow: {
		en: "Follow",
		es: "Seguir",
	},
	email: {
		en: "Email",
		es: "Correo electrónico",
	},
	name: {
		en: "Name",
		es: "Nombre",
	},
	cancel : {
		es: "Cancelar",
		en: "Cancel"
	},
	send: {
		es: "Enviar",
		en: "Send"
	},
	

};

export const national_text = {
	ques: {
		en: "Isn't this where you want to travel?",
		es: "¿No es a donde quieres viajar?",
	},
	sub_ques: {
		en: "Make the trip of your dreams with us to any part of Peru",
		es: "Haz el viaje de tus sueños con nosotros a cualquier parte del Perú",
	},
};
export const international_text = {
	ques: {
		en: "Isn't this where you want to travel?",
		es: "¿No es a donde quieres viajar?",
	},
	sub_ques: {
		en: "Make the trip of your dreams with us to any part of the world",
		es: "Haz el viaje de tus sueños con nosotros a cualquier parte del mundo",
	},
};

export const aboutus_text = {
	dosg: {
		en: "AT DOS GARDENIAS TOURS",
		es: "EN DOS GARDENIAS TOURS",
	},
	msg: {
		en: "we are your perfect ally<br/>to make your dream trip<br/>a reality",
		es: "somos tu aliado <br/>perfecto para hacer<br/>realidad tu viaje soñado",
	},
	about: {
		en: "A Peruvian travel agency<br/>authorized by the Ministry of<br/>Foreign Trade and Tourism, your guide and companion in creating unique and unforgettable experiences.",
		es: "Una agencia de viajes peruana<br/>autorizada por el Ministerio de<br/>Comercio Exterior y Turismo, tu guía y compañera en la creación de experiencias únicas e inolvidables.",
	},
};

export const vpromo_text = {
	title: {
		en: "SPECIAL DESTINIES",
		es: "DESTINOS ESPECIALES",
	},
	subtitle_1: {
		es:"¡MÁS DE LO QUE IMAGINAS!\nACTIVIDADES PARA TODOS LOS GUSTOS",
		en:"MORE THAN YOU IMAGINE!\nACTIVITIES FOR ALL TASTES"
	},

	subtitle_2: {
		en: "We have unforgettable adventures for your final year.<br/>What type of trip do you want to take?",
		es: "Tenemos aventuras inolvidables para tu último año",
	},


};

export const wedding_text = {
	title: {
		es: "NUESTROS SERVICIOS",
		en: "OUR SERVICES"
	},
	symbol: {
		es: "Boda simbólica",
		en: "Symbolic wedding"
	},
	religious: {
		es: "Boda religiosa",
		en: "Religious wedding"
	},
	civil : {
		es: "Boda civil",
		en: "Civil wedding"
	},
	ask: {
		es: "¿POR QUÉ NOSOTROS?",
		en: "¿WHY US?"
	},
	ask_detail: {
		es: "Tu boda es un momento único, y nosotros lo hacemos inolvidable. Te ofrecemos destinos soñados, planificación personalizada y experiencias exclusivas para que solo te preocupes por disfrutar",
		en: "Your wedding is a unique moment, and we make it unforgettable. We offer you dream destinations, personalized planning, and exclusive experiences so all you have to do is enjoy."
	},
	offer: {
		en:" WHAT WE OFFER YOU?",
		es: "¿QUÉ TE OFRECEMOS?"
	},
	services: {
		es: "Cena o almuerzo\nRecepción\nCóctel\nOpen bar\nAsistencia permanente\nUpgrade a los novios por\npasar los 100 invitados\nLuna de miel\nTarjeta de asistencia",
		en: "Dinner or lunch\nReception\nCocktail\nOpen bar\nPermanent assistance\nUpgrade for the couple for\nhaving more than 100 guests\nHoneymoon\nAssistance card"
	},
	services_help:{
		es: "Variable según destino y hotel elegido*",
		en: "Variable depending on the destination and hotel chosen*"
	},
	last: {
		es: "¡HAZ DE TU BODA UN VIAJE\n PARA RECORDAR TODA LA VIDA!",
		en: "Make your wedding a journey\n to remember for a lifetime!"
	},
	coti: {
		es: "Cotizar mi boda",
		en: "Get a quote for my wedding"
	}
}

export const coti_dialog_text = {
	title: {
		es: "Cotizar paquete",
		en: "Get a quote"
	},
	message: {
		es: "Para proporcionarte más información sobre alguna\n promoción completa el siguiente formulario",
		en: "To provide you with more information about a promotion,\n please complete the following form"
	},
	city: {
		es: "Ciudad",
		en: "City"
	},
	name: {
		es: "Nombre",
		en: "Name"
	},
	email: {
		es: "Correo electrónico",
		en: "Email"
	},
	from: {
		es: "Fecha de ida",
		en: "Departure date"
	},
	to: {
		es: "Fecha de regreso",
		en: "Return date"
	},
	kind: {
		es: "Tipo de viaje",
		en: "Type of trip"
	},
	number: {
		es: "Nro de pasajeros",
		en: "Number of passengers"
	},
	comments: {
		es: "Comentarios adicionales",
		en: "Additional comments"
	},
	items: {
		full: {
			es: "Paquete Vuelo y hotel",
			en: "Flight & hotel"
		},
		flight: {
			es: "Vuelo",
			en: "Flight"
		},
		hotel: {
			es: "Hotel",
			en: "Hotel"
		}

	}
}

export const confirm_dialog_text = {
	title: {
		en: "DONE!\nCHECK YOUR EMAIL, YOU'VE RECEIVED\nA FREE TRAVEL GUIDE",
		es: "¡LISTO!\nREVISA TU EMAIL, TE HA LLEGADO\nUNA GUÍA DE VIAJE GRATIS"
	},
	subtitle: {
		en: "Thank you for subscribing to our newsletter!",
		es: "¡Gracias por suscribirte a nuestro newsletter!"
	}
}