import { useContext } from "react";
import Footer from "../components/footer";
import Header, { HeaderContext } from "../components/header";
import { wedding_text } from "../translation";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {  responsive } from "../components/utils";

export default function Wedding(){
    const { lang, setOpenCoti } = useContext(HeaderContext);
    return (
        <div className="font-poppins">
            <Header />
            <div>
                <div className="bg-dggray-200">
					<img
						className="hidden md:block"
						alt="promo-image"
						src={`/images/wedding/head_web_[${lang}].png`}
					/>
					<img
						className="block md:hidden"
						alt="promo-image"
						src={`/images/wedding/head_mobile_[${lang}].png`}
					/>
				</div>
                <div className="py-20  text-center ">
                    <p className="text-4xl md:text-5xl  font-ivymode pb-10 text-dggray-500">
						{wedding_text.title[lang]}
					</p>
                    <div className="hidden md:grid grid-cols-3">
                        <div>
                            <img
                                alt="promo-image"
                                src={"/images/wedding/w1.png"}
                                className="m-auto h-40 md:h-96 md:max-h-fit px-2"
                            />
                            <p className="text-md md:text-2xl font-poppins text-center mt-4 text-dggray-500">
                                {wedding_text.symbol[lang]}
                            </p>
                        </div>
                            <div>
                                <img
                                    alt="promo-image"
                                    src={"/images/wedding/w2.png"}
                                    className="m-auto h-40 md:h-96 md:max-h-fit px-2"
                                />
                                <p className="text-md md:text-2xl font-poppins text-center mt-4 text-dggray-500">
                                    {wedding_text.religious[lang]}
                                </p>
                            </div>
                            <div>
                                <img
                                    alt="promo-image"
                                    src={"/images/wedding/w3.png"}
                                    className="m-auto h-40 md:h-96 md:max-h-fit px-2 "
                                />
                                <p className="text-md md:text-2xl font-poppins text-center mt-4 text-dggray-500">
                                    {wedding_text.civil[lang]}
                                </p>
                        </div>
                    </div>
                    <div className="block md:hidden">

                        <Carousel
                                infinite
                                responsive={responsive}
                                autoPlay
                                arrows={true}                                
                            >

                            <div>
                                <img
                                    alt="promo-image"
                                    src={"/images/wedding/w1.png"}
                                    className="m-auto h-48 md:h-auto md:max-h-fit px-2"
                                />
                                <p className="text-md md:text-2xl font-poppins text-center mt-4">
                                    {wedding_text.symbol[lang]}
                                </p>
                            </div>
                            <div>
                                <img
                                    alt="promo-image"
                                    src={"/images/wedding/w2.png"}
                                    className="m-auto h-48 md:h-auto md:max-h-fit px-2"
                                />
                                <p className="text-md md:text-2xl font-poppins text-center mt-4">
                                    {wedding_text.religious[lang]}
                                </p>
                            </div>
                            <div>
                                <img
                                    alt="promo-image"
                                    src={"/images/wedding/w3.png"}
                                    className="m-auto h-48 md:h-auto md:max-h-fit px-2"
                                />
                                <p className="text-md md:text-2xl font-poppins text-center mt-4">
                                    {wedding_text.civil[lang]}
                                </p>
                            </div>
                        </Carousel>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 mt-20">
                        <div className="bg-dgwhite px-8 md:px-60 text-dggray-500">
                            <p className="font-ivymode text-3xl md:text-4xl mt-14 mb-4 md:mb-8">{wedding_text.ask[lang]}</p>
                            <p className="text-2xl md:text-3xl mb-14 px-4">{wedding_text.ask_detail[lang]}</p>
                        </div>
                        <div className="bg-dggreen px-8 md:px-60  text-dgwhite whitespace-pre">
                            <p className="font-ivymode text-3xl md:text-4xl mt-14 mb-4 md:mb-8">{wedding_text.offer[lang]}</p>
                            <p className="text-2xl md:text-3xl mb-4">{wedding_text.services[lang]}</p>
                            <p className="text-md pb-10">{wedding_text.services_help[lang]}</p>
                        </div>
                    </div>
                    <div className="pt-20 md:pt-24 px-4 md:px-0">
                        <p className="font-ivymode text-4xl md:text-5xl whitespace-normal md:whitespace-pre text-dgbrown-300 mb-8">{wedding_text.last[lang]}</p>
                        <button onClick={()=>setOpenCoti(true)} className="rounded-full px-14  md:px-8 py-2 shadow-lg hover:shadow-xl bg-white
                         text-dggreen hover:text-dgblue text-lg md:text-2xl font-poppins">
						    {wedding_text.coti[lang]}
					    </button>
                    </div>
                    
                </div>

            </div>
            <Footer />
        </div>
    )
}