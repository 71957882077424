import { useContext } from "react";
import { HeaderContext } from "./header";

export default function Info({
	title,
	subtitle,
	button_txt,
	bg,
	title_color,
	subtitle_color,
	url,
}) {

	const {setOpenCoti} = useContext(HeaderContext)
	
	return (
		<div
			className={`grid  grid-cols-1 md:grid-cols-4 gap-y-6 md:gap-y-0 px-10   py-14 md:p-20  ${
				bg ? bg : "bg-dgblue"
			}`}
		>
			<div className="col-span-3">
				<p
					className={`text-3xl md:text-5xl mb-4 font-ivymode  ${
						title_color ? title_color : "text-white"
					}   `}
				>
					{title}
				</p>
				<p
					className={`text-xl md:text-3xl font-poppins ${
						subtitle_color ? subtitle_color : "text-white"
					}`}
				>
					{subtitle}
				</p>
			</div>
			<div className="col-span-1 flex justify-center items-center">
				{url && <a
					href={url}
					className="rounded-full px-14  md:px-8 py-2 shadow-lg hover:shadow-xl bg-white text-dggreen hover:text-dgblue text-2xl font-poppins"
				>
					{button_txt}
				</a>}
				{
					!url &&
					<button onClick={()=>setOpenCoti(true)} className="rounded-full px-14  md:px-8 py-2 shadow-lg hover:shadow-xl bg-white text-dggreen hover:text-dgblue text-2xl font-poppins">
						{button_txt}
					</button>

				}
			</div>
		</div>
	);
}
