import Carousel from "react-multi-carousel";
import Footer from "../components/footer";
import Header, { HeaderContext } from "../components/header";
import { NATIONAL, responsive } from "../components/utils";
import "react-multi-carousel/lib/styles.css";
import Info from "../components/info";
import { useContext, useEffect, useState } from "react";
import getPromos from "../providers/promos";
import { button_text, home_text, national_text } from "../translation";

export default function National() {
	const { lang } = useContext(HeaderContext);
	const [promos, setPromos] = useState([]);

	const fetchPromos = async () => {
		try {
			const promosData = await getPromos(NATIONAL);
			setPromos(promosData);
		} catch (error) {
			console.error("Failed to fetch promos:", error);
		}
	};
	useEffect(() => {
		fetchPromos();
	}, []);

	// const onPromoClick = (item) => {
	// 	setItem(item);
	// 	setOpenInfo(true);
	// };
	return (
		<div>
			<Header />

			<div>
				<div className="bg-dggray-200">
					<img
						className="hidden md:block"
						alt="promo-image"
						src={`/images/national/head_web_[${lang}].png`}
					/>
					<img
						className="block md:hidden"
						alt="promo-image"
						src={`/images/national/head_mobile_[${lang}].png`}
					/>
					<p className="text-3xl md:text-5xl my-14 text-center text-dgtitle font-ivymode">
						{home_text.promos[lang]}
					</p>
					<div className="px-4 md:px-32 font-poppins ">
						<Carousel
							infinite
							responsive={responsive}
							autoPlay
							arrows={true}
						>
							{promos.map((it, idx) => (
								<a
									href={it.pdf}
									target="_blank"
									rel="noreferrer"
									key={"key-" + idx}
									className="hover:cursor-pointer hover:shadow-lg"
									// onClick={() => onPromoClick(it)}
								>
									<img
										alt="promo-image"
										src={it.img}
										className="m-auto h-40 md:h-auto md:max-h-fit px-2"
									/>
									<p className="text-md md:text-2xl font-poppins text-center mt-4">
										{it.name}
									</p>
								</a>
							))}
						</Carousel>
						<div className="h-14">&nbsp;</div>
						<Info
							title={national_text.ques[lang]}
							subtitle={national_text.sub_ques[lang]}
							button_txt={button_text.coti[lang]}
							bg={"bg-dggray-200"}
							title_color={"text-dgtitle"}							
							subtitle_color={"text-gray-400"}
						/>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
