import { home_text } from "../translation";
import Dialog from "./dialog";
import askChatAI from "../providers/chatai";
import { useContext, useEffect, useState } from "react";
import { HeaderContext } from "./header";

export default function ChatDialog({ isOpen, onClose, title, first_answer }) {
	const { lang, setOpenCoti } = useContext(HeaderContext);
	const [question, setQuestion] = useState("");
	const [answer, setAnswer] = useState("");
	useEffect(() => {
		setAnswer(first_answer);
	}, [first_answer]);
	const handleCoti = () => {
		setOpenCoti(true);
		onClose();
	};
	const handleAsk = (e) => {
		// mostrar algun loading
		e.preventDefault();

		askChatAI(question.trim())
			.then((res) => {
				setAnswer(res);
				setQuestion("");
			})
			.catch((e) => console.error("error", e));
	};

	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog title={title} isOpen={isOpen} onClose={handleClose}>
			<form onSubmit={handleAsk}>

				<div className="flex flex-row  md:min-h-60 min-h-[45vh] max-h-[55vh]" >
					<div className="min-w-10  ">
						<img
							src="/images/home/ia.png"
							className="w-20 md:w-10"
							alt="ai_icon"
						/>
					</div>
					<div className="max-h-full md:max-h-[50vh]  overflow-auto ">
						<p className="whitespace-pre-wrap h-full md:h-3/4  ">
							{answer}
							
						</p>
					</div>
				</div>
				<div className=" ">
					<input
						onChange={(e) => setQuestion(e.target.value)}
						value={question}
						className="hidden md:block text-md px-4 py-2 w-full border-2 border-dgtitle rounded-full my-4 text-black"
						placeholder={home_text.placeholder[lang]}
					/>
					<textarea 
						rows={3}
						onChange={(e) => setQuestion(e.target.value)}
						value={question} placeholder={home_text.placeholder[lang]}
						className=" block md:hidden text-md px-4 py-2 w-full border-2 border-dgtitle rounded-xl my-4 text-black min-h-4">

					</textarea>
				</div>
				<div className="grid grid-cols-4">
					<div className="hidden md:block col-span-4 md:col-span-2">
						<button
							type="button"
							onClick={onClose}
							className="ml-8 text-lg border-2 rounded-full text-dgtitle border-dgtitle hover:bg-dgwhite hover:text-black  px-4 py-1 font-poppins shadow-lg"
						>
							Cerrar
						</button>
					</div>
					<div className="col-span-4 md:col-span-2 flex flex-col md:flex-row gap-y-2 items-center">
						<button
							type="submit"
							
							className="ml-auto bg-dggreen rounded-full px-6 py-2 md:py-1 text-lg text-white font-poppins w-full md:w-fit"
						>
							Preguntar
						</button>
						<button
							type="button"
							onClick={handleCoti}
							className="ml-auto bg-dgblue rounded-full px-6 py-2 md:py-1 text-lg text-white font-poppins w-full md:w-fit"
						>
							Ir a Cotizar
						</button>
					</div>
				</div>
			</form>
		</Dialog>
	);
}
