import Dialog from "./dialog";

export default function PDFDialog({ item, open, onClose }) {
	return (
		<Dialog onClose={onClose} isOpen={open} title={item ? item.name : ""}>
			<iframe
				src={item ? item.pdf : ""} // Replace with your PDF file path
				className="w-full h-[85vh] max-h-full md:h-[75vh] border-none"
				title="PDF Viewer"
			/>
		</Dialog>
	);
}
