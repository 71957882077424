import {
	ESNNA,
	FACE_LINK,
	INST_LINK,
	MAIL_LINK,
	MINCETUR,
	PROTECTION,
	QUALITY,
	TADVISOR_LINK,
	TERMS,
	TK_LINK,
	WS_LINK,
	YT_LINK,
} from "./utils";

import { button_text, footer_text } from "../translation";
import p from "../../package.json";
import { useContext, useRef, useState } from "react";
import { HeaderContext } from "./header";
import saveSub from "../providers/subscription";
import { AppContext } from "../App";
import ConfirmSuscriptionDialog from "./confirm_suscription_dialog";

export default function Footer() {
	const { lang } = useContext(HeaderContext);
	const { handleLoading } = useContext(AppContext);
	const userRef = useRef();
	const emailRef = useRef();
	const [open, setOpen] = useState(false);

	const sendEmail = (e) => {
		e.preventDefault();
		const body = {
			name: userRef.current.value,
			email: emailRef.current.value,
		};
		handleLoading(true);
		

		saveSub(body)
			.then((res) => {
				if (res.code === 409) {					
					alert("El correo electrónico ingresado ya esta registrado");
				} else {
					setOpen(true);
					
				}
			})
			.catch((e) => {
				console.log("error", e);
				alert(`Error ${e}`);
			})
			.finally(() => handleLoading(false));
	};

	return (
		<footer className=" bg-dggray-200 font-poppins">
			<ConfirmSuscriptionDialog open={open} onClose={()=>setOpen(false)} />
			<div className="py-10 px-6 md:px-0 w-full">
				<div className="grid grid-cols-1 md:grid-cols-2  gap-y-8 md:gap-y-0 px-0 md:px-20">
					<div>
						<img alt="promo-image" src="/images/logo.png" />
					</div>
					<div
						id="icons"
						className="hidden md:flex flex-row gap-6 justify-center md:justify-end  mr-0 md:mr-10 items-center my-4 "
					>
						<a target="_blank" rel="noreferrer" href={YT_LINK}>
								<img
									alt="promo-image"
									className="h-8"
									src="/images/yt-footer.png"
								/>
						</a>
						<a target="_blank" rel="noreferrer" href={FACE_LINK}>
							<img
								alt="promo-image"
								className="h-8"
								src="/images/face-footer.png"
							/>
						</a>
						<a target="_blank" rel="noreferrer" href={INST_LINK}>
							<img
								alt="promo-image"
								className="h-8"
								src="/images/inst-footer.png"
							/>
						</a>
						<a target="_blank" rel="noreferrer" href={TK_LINK}>
							<img
								alt="promo-image"
								className="h-8"
								src="/images/tk-footer.png"
							/>
						</a>
						<a target="_blank" rel="noreferrer" href={WS_LINK}>
							<img
								alt="promo-image"
								className="h-8"
								src="/images/ws-footer.png"
							/>
						</a>
						<a target="_blank" rel="noreferrer" href={TADVISOR_LINK}>
							<img
								alt="promo-image"
								className=" h-8 my-auto"
								src="/images/tripadvisor.png"
							/>
						</a>
						<a
							target="_blank"
							rel="noreferrer"
							href={`mailto:${MAIL_LINK}`}
						>
							<img
								alt="promo-image"
								className=" h-8"
								src="/images/mail.png"
							/>
						</a>
					</div>
				</div>

				<div className="mt-10 md:mt-0 grid grid-cols-2 md:grid-cols-1 lg:grid-cols-5 gap-4 ">
					<div className="col-span-2 md:col-span-2 px-0 md:px-4 flex flex-col justify-center items-center">
						<p
							dangerouslySetInnerHTML={{
								__html: footer_text.newsletter[lang],
							}}
							className="text-2xl text-center font-ivymode font-semibold"
						/>

						<form onSubmit={sendEmail} className="w-full md:w-1/2">
							<div className="relative w-full  flex flex-col ">
								<input
									required
									ref={userRef}
									type="text"
									placeholder={button_text.name[lang]}
									className="mx-auto  pl-4 pr-14 	 md:mb-0 mt-10 border-b-2 border-black  py-2 w-full md:max-w-[100%] bg-transparent "
								/>
								<input
									required
									ref={emailRef}
									type="email"
									placeholder={button_text.email[lang]}
									className="mx-auto  pl-4 pr-0 mb-10 md:mb-0 mt-4 border-b-2 border-black py-2 w-full md:max-w-[100%] bg-transparent "
								/>
								<button
									type="submit"
									className="absolute bottom-12  right-4  md:bottom-2  md:right-2  hover:shadow-lg shadow-md border-0 rounded-full bg-white"
								>
									<img
										src="/images/arrow-right-small.png"
										alt="send_icon"
									/>
								</button>
							</div>
						</form>
						<div
							id="icons"
							className="flex md:hidden flex-row flex-wrap gap-y-8 gap-x-10 md:gap-y-0 md:gap-x-4  justify-center md:justify-end  mr-0 md:mr-10 items-center mt-4 md:mt-0 mb-10 md:my-4 "
						>
							<a target="_blank" rel="noreferrer" href={YT_LINK}>
								<img
									alt="promo-image"
									className="h-8"
									src="/images/yt-footer.png"
								/>
							</a>

							<a target="_blank" rel="noreferrer" href={FACE_LINK}>
								<img
									alt="promo-image"
									className="h-8"
									src="/images/face-footer.png"
								/>
							</a>
							<a target="_blank" rel="noreferrer" href={INST_LINK}>
								<img
									alt="promo-image"
									className="h-8"
									src="/images/inst-footer.png"
								/>
							</a>
							<a target="_blank" rel="noreferrer" href={TK_LINK}>
								<img
									alt="promo-image"
									className="h-8"
									src="/images/tk-footer.png"
								/>
							</a>
							<a target="_blank" rel="noreferrer" href={WS_LINK}>
								<img
									alt="promo-image"
									className="h-8"
									src="/images/ws-footer.png"
								/>
							</a>
							<a target="_blank" rel="noreferrer" href={TADVISOR_LINK}>
								<img
									alt="promo-image"
									className=" h-8 my-auto"
									src="/images/tripadvisor.png"
								/>
							</a>
							<a
								target="_blank"
								rel="noreferrer"
								href={`mailto:${MAIL_LINK}`}
							>
								<img
									alt="promo-image"
									className=" h-8"
									src="/images/mail.png"
								/>
							</a>
						</div>
					</div>
					<div className="flex flex-col gap-y-2 font-i">
						<p className="text-xl text-dgtitle font-ivymode font-bold">
							{footer_text.services[lang]}
						</p>
						<a href={WS_LINK} className="text-lg hover:underline" target="_blank"
							rel="noreferrer">
							{footer_text.services.coti[lang]}
						</a>
						<a href={"/nacional"} className="text-lg hover:underline">
							{footer_text.services.national[lang]}
						</a>
						<a href="/internacional" className="text-lg hover:underline">
							{footer_text.services.international[lang]}
						</a>
						<a href="/vpromo" className="text-lg hover:underline">
							{footer_text.services.vpromo[lang]}
						</a>
					</div>
					<div className="flex flex-col gap-y-2">
						<p className="text-xl text-dgtitle font-ivymode font-bold">
							{footer_text.certis[lang]}
						</p>
						<a
							href={ESNNA}
							target="_blank"
							rel="noreferrer"
							className="text-lg hover:underline font-poppins"
						>
							{footer_text.certis.esnna[lang]}
						</a>
						<a
							href={TERMS}
							target="_blank"
							rel="noreferrer"
							className="text-lg hover:underline font-poppins"
						>
							{footer_text.certis.terms[lang]}
						</a>
						<a
							href={MINCETUR}
							target="_blank"
							rel="noreferrer"
							className="text-lg hover:underline font-poppins"
						>
							{footer_text.certis.mincetur[lang]}
						</a>
					</div>
					<div className="flex flex-col gap-y-2">
						<p className="text-xl text-dgtitle font-ivymode font-bold">
							{footer_text.policy[lang]}
						</p>
						{/* <a className="text-lg hover:underline font-poppins" href="/#">
							{footer_text.policy.secure[lang]}
						</a> */}
						{/* <a className="text-lg hover:underline font-poppins" href="/#">
							{footer_text.policy.privacy[lang]}
						</a> */}
						<a
							href={PROTECTION}
							className="text-lg hover:underline font-poppins"
							target="_blank"
							rel="noreferrer"
						>
							{footer_text.policy.protection[lang]}
						</a>
						<a
							href={QUALITY}
							className="text-lg hover:underline font-poppins"
							target="_blank"
							rel="noreferrer"
						>
							{footer_text.policy.quality[lang]}
						</a>
					</div>
				</div>
			</div>
			<div className="text-center border-t-2 py-1 mr-4 font-poppins">
				<a href={"http://www.devosinc.com"} target="_blank" rel="noreferrer">{footer_text.dev[lang]}</a>
				<span className="float-right ">{p.version}</span>
			</div>
		</footer>
	);
}
