import { useContext, useEffect, useState } from "react";
import Footer from "../components/footer";
import Header, { HeaderContext } from "../components/header";
import { responsive_social, TIKTOK, TK_LINK } from "../components/utils";
import { button_text, vpromo_text } from "../translation";
import Carousel from "react-multi-carousel";
import getLinks from "../providers/links";
import { TikTokEmbed } from "react-social-media-embed";

export default function VPromo() {
	const { lang, setOpenCoti } = useContext(HeaderContext);
	const [links, setLinks] = useState([]);

	const fetchLinks = async () => {
		try {
			const linksData = await getLinks(TIKTOK);
			setLinks(linksData);
		} catch (error) {
			console.error("Failed to fetch promos:", error);
		}
	};

	useEffect(() => {
		fetchLinks();
	}, []);

	

	return (
		<div>
			<Header />
			<div>
				<div className="bg-dggray-200">
					<img
						className="hidden md:block"
						alt="promo-image"
						src={`/images/vpromo/head_web_[${lang}].png`}
					/>
					<img
						className="block md:hidden"
						alt="promo-image"
						src={`/images/vpromo/head_mobile_[${lang}].png`}
					/>
				</div>

				<div className="px-4 py-10 md:p-20">
					<p className="text-3xl md:text-5xl font-ivymode text-center pb-10 text-dggreen">{vpromo_text.title[lang]}</p>
					<div className="flex flex-row font-poppins justify-center flex-wrap gap-y-4 md:gap-x-10">
						<div>
							<img src="/images/vpromo/vp1.png" alt="Máncora" className="m-auto h-56 md:h-96 md:max-h-fit px-2"/>
							<p className="text-xl md:text-3xl font-poppins text-center mt-4">Máncora</p>
						</div>
						<div>
							<img src="/images/vpromo/vp2.png" alt="Punta sal" className="m-auto h-56 md:h-96 md:max-h-fit px-2"/>
							<p className="text-xl md:text-3xl font-poppins text-center mt-4">Punta Sal</p>
						</div>
						<div>
							<img src="/images/vpromo/vp3.png" alt="Cusco" className="m-auto h-56 md:h-96 md:max-h-fit px-2"/>
							<p className="text-xl md:text-3xl font-poppins text-center mt-4">Cusco</p>
						</div>
						<div>
							<img src="/images/vpromo/vp4.png" alt="Selva Central" className="m-auto h-56 md:h-96 md:max-h-fit px-2"/>							
							<p className="text-xl md:text-3xl font-poppins text-center mt-4">Selva Central</p>							

						</div>
					</div>
				</div>
				<div className="px-8 py-14 md:p-20 bg-dgblue text-center ">
					<p className="text-3xl md:text-5xl text-white font-ivymode mb-10 whitespace-pre-wrap md:whitespace-pre">
						{vpromo_text.subtitle_1[lang]}
					</p>
					<p						
						className="text-2xl md:text-3xl text-white font-poppins mb-10"
					>
						{vpromo_text.subtitle_2[lang]}
					</p>

					<button onClick={()=>setOpenCoti(true)} className="rounded-full px-14  md:px-8 py-2 shadow-lg hover:shadow-xl bg-white
                         text-dggreen hover:text-dgblue text-xl md:text-2xl font-poppins">
						    {button_text.coti[lang]}
					</button>
					
				</div>
				<div className="px-4 md:px-40 flex flex-col  py-20">
					<div className="flex flex-row justify-between items-center">
						<p className="text-xl md:text-4xl text-dggray-500 font-ivymode font-bold">
							{button_text.follow_tk[lang]}
						</p>
						<a
							href={TK_LINK}
							target="_blank"
							rel="noreferrer"
							className="hover:bg-dggreen hover:shadow-xl text-lg font-poppins px-10 py-1 bg-white border-2 border-dggray-500 rounded-full"
						>
							{button_text.follow[lang]}
						</a>
					</div>
					<div className="mt-10 md:my-10 gap-x-4">
						<Carousel autoPlay infinite responsive={responsive_social}>
							{links.map((it, idx) => (
								<div
									key={"key-" + idx}
									className="flex items-center justify-center"
								>
									{/* <TKPost url={it.url} /> */}

									<TikTokEmbed url={it.url} />
								</div>
							))}
						</Carousel>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
