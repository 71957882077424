// src/App.js
import React, { createContext, useState } from "react";

import Home from "./pages/home";
import Aboutus from "./pages/aboutus";
import National from "./pages/national";
import International from "./pages/international";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Providers from "./providers/header";
import VPromo from "./pages/vpromo";
import Wedding from "./pages/wedding";

export const AppContext = createContext();
function App() {
	const [loading, setLoading] = useState(false);

	const handleLoading = (v) => {
		setLoading(v);
		if (v) {
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}
	};

	return (
		<div id="home" className="relative">
			<Providers>
				<AppContext.Provider value={{ loading, handleLoading }}>
					{loading && (
						<div className="fixed inset-0 bg-gray-700 bg-opacity-75 flex items-center justify-center z-50">
							<img
								src="/images/loading.gif"
								className="m-auto h-32"
								alt="plane animation"
							/>
						</div>
					)}
					<Router>
						<Routes>
							<Route exact path="/" element={<Home />} />
							<Route path="/nosotros" element={<Aboutus />} />
							<Route path="/nacional" element={<National />} />
							<Route path="/internacional" element={<International />} />
							<Route path="/vpromo" element={<VPromo />} />
							<Route path="/bodas" element={<Wedding />} />
						</Routes>
					</Router>
				</AppContext.Provider>
			</Providers>
		</div>
	);
}

export default App;
