import { BACKEND_URL } from "../components/utils";

export default async function askChatAI(message) {
	try {
		let response = await fetch(`${BACKEND_URL}/conversation`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ message: message }),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok " + response.statusText);
		}

		// Parse the JSON from the response
		const data = await response.json();

		return data.data;

		// Log the data to the console
	} catch (e) {
		throw new Error("Cant retrieve chat response");
	}
}
