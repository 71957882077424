import { useContext, useEffect, useState } from "react";
import Footer from "../components/footer";
import Header, { HeaderContext } from "../components/header";
import { INST_LINK, INSTAGRAM, responsive_social } from "../components/utils";
import { aboutus_text, button_text, home_text } from "../translation";
import Carousel from "react-multi-carousel";
import getLinks from "../providers/links";
import { InstagramEmbed } from "react-social-media-embed";

export default function AboutusPage() {
	const { lang, setOpenCoti } = useContext(HeaderContext);
	const [links, setLinks] = useState([]);

	const fetchLinks = async () => {
		try {
			const linksData = await getLinks(INSTAGRAM);
			setLinks(linksData);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		fetchLinks();
	}, []);
	return (
		<div>
			<Header />
			<div>
				<div className="px-0 ">
					<img
						className="hidden md:block"
						alt="promo-image"
						src={`/images/aboutus/head_web_[${lang}].png`}
					/>
					<img
						className="block md:hidden"
						alt="promo-image"
						src={`/images/aboutus/head_mobile_[${lang}].png`}
					/>

					<div className="grid grid-cols-1 md:grid-cols-2">
						<div className="px-0 py-20 md:px-20 md:py-32  bg-dggreen text-white">
							<p className="font-poppins text-lg md:text-2xl text-center mb-2 md:mb-8">
								{aboutus_text.dosg[lang]}
							</p>
							<p
								dangerouslySetInnerHTML={{
									__html: aboutus_text.msg[lang],
								}}
								className="font-ivymode text-4xl md:text-7xl italic text-center"
							/>
						</div>
						<div className="px-10 md:px-20 py-12 md:py-32 bg-dggray-200">
							<p
								dangerouslySetInnerHTML={{
									__html: aboutus_text.about[lang],
								}}
								className="font-ivymode text-3xl md:text-5xl text-center text-dggray-500"
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 md:grid-cols-3 md:pt-10">
						<div className="order-3 md:order-1 flex flex-row md:flex-col justify-center items-center gap-y-10  gap-x-4 md:gap-0 col-span-2 md:col-span-1 px-2">
							<div className="flex-1 md:flex-auto flex flex-col  mt-6 justify-center items-center ">
								<p className="text-center font-poppins text-lg md:text-2xl my-4">
									{home_text.certi_1[lang]}
								</p>
								<img
									className="h-32 md:h-auto"
									alt="promo-image"
									src="/images/home/certis/iso.png"
								/>
							</div>

							<div className="flex-1 md:flex-auto flex flex-col  justify-center mt-6  ">
								<p className="text-center font-poppins text-lg md:text-2xl my-4 md:my-0">
									{home_text.certi_3[lang]}
								</p>
								<img
									className="h-32 md:h-auto md:mt-2"
									alt="promo-image"
									src="/images/home/certis/peru.png"
								/>
							</div>
						</div>

						<div className="order-2 hidden md:flex ">
							<img
								alt="promo-image"
								className="h-3/4  m-auto"
								src="/images/aboutus/center.png"
							/>
						</div>

						<div className=" order-1 md:order-3 col-span-2 md:col-span-1 flex flex-col justify-center items-center md:gap-y-10">
							<p className="font-poppins text-lg md:text-2xl ">
								{home_text.certi_2[lang]}
							</p>
							<div className="flex flex-row md:flex-col md:gap-y-4 gap-x-4">
								<img
									className="h-24 md:h-auto"
									alt="promo-image"
									src="/images/aboutus/mincetur.png"
								/>
								<img
									className="h-24  md:h-auto md:mt-8"
									alt="promo-image"
									src="/images/aboutus/iata.png"
								/>
							</div>
						</div>
					</div>
					<div className="flex pb-6 md:pb-20 mt-10 md:mt-0">
						<button
							onClick={() => setOpenCoti(true)}
							className="m-auto shadow-custom-md rounded-full border-2 px-10 py-2 text-2xl border-dggray-400 font-poppins hover:shadow-custom-lg"
						>
							{button_text.coti[lang]}
						</button>
					</div>
					<div className="px-4 md:px-40 flex flex-col  py-20">
						<div className="flex flex-row justify-between items-center">
							<p className="text-xl md:text-4xl text-dggray-500 font-ivymode font-bold">
								{button_text.follow_inst[lang]}
							</p>
							<a
								href={INST_LINK}
								target="_blank"
								rel="noreferrer"
								className="hover:bg-dggreen hover:shadow-xl text-lg font-poppins px-10 py-1 bg-white border-2 border-dggray-500 rounded-full"
							>
								{button_text.follow[lang]}
							</a>
						</div>
						<div className="mt-10 md:my-10 gap-x-10">
							<Carousel responsive={responsive_social} autoPlay infinite>
								{links.map((it, idx) => (
									<div
										key={"key-" + idx}
										className="flex justify-center h-fit"
									>
										
										<InstagramEmbed url={it.url}  width={330} captioned/>


									</div>
								))}
							</Carousel>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
