"use client";
import { HeaderContext } from "../components/header";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

export default function Providers({ children }) {
	const [lang, setLang] = useState("es");
	const [openCoti, setOpenCoti] = useState(false);
	const [openInfo, setOpenInfo] = useState(false);
	const [item, setItem] = useState(null);
	const [openSorry, setOpenSorry] = useState(false);
	useEffect(() => {
		setLang(Cookies.get("ulang") ?? "es");
	}, []);

	return (
		<HeaderContext.Provider
			value={{
				lang,
				setLang,
				openCoti,
				setOpenCoti,
				openInfo,
				setOpenInfo,
				item,
				setItem,
				openSorry,
				setOpenSorry,
			}}
		>
			{children}
		</HeaderContext.Provider>
	);
}
